import { defineStore } from "pinia";
import ProjectDataFetcher from "@/services/connectors/projectConnector";
import { ProjectItem, ProjectType } from "@/services/entities/ProjectItem";
import { UserProjectItem } from "@/services/entities/UserProjectItem";
import { DefinitionItem } from "@/services/entities/DefinitionItem";
import { getServerUrl } from "@/common/urls";
import {
  closeToast,
  showLoading,
  showSuccess,
  showToastError,
} from "@/toastification";
import { parseError } from "@/common/util";
import { useSettingsStore } from "./setting";
import { ServiceItem } from "@/services/entities/ServiceItem";

const project = new ProjectDataFetcher(getServerUrl());

export const useProjectStore = defineStore("project", {
  state: () => ({
    projects: {
      data: [] as ProjectItem[],
      loader: false,
    },
  }),
  actions: {
    async loadProjects(): Promise<void> {
      try {
        this.projects.loader = true;
        this.projects.data = await project.getAll();
        this.projects.data.forEach((a) => {
          a.projectStartPicker = {
            month: new Date(a.projectStart).getMonth(),
            year: new Date(a.projectStart).getFullYear(),
          };
          a.projectEndPicker = {
            month: new Date(a.projectEnd).getMonth(),
            year: new Date(a.projectEnd).getFullYear(),
          };
          a.usersProject.forEach((b) => {
            b.participations.forEach((c) => {
              c.monthRange = [] as any;
              c.monthRange[0] = {
                month: new Date(c.from).getMonth(),
                year: new Date(c.from).getFullYear(),
              };
              c.monthRange[1] = {
                month: new Date(c.to).getMonth(),
                year: new Date(c.to).getFullYear(),
              };
            });
          });          
          a.projectType = ProjectType[a.projectTypeId];
        });
      } catch (e: any) {
        console.error(e);
      } finally {
        this.projects.loader = false;
      }
    },
    async createProject(projectItem: ProjectItem): Promise<string> {
      try {
        const save = showLoading("Creating project");
        projectItem.service = null;
        const projectId = await project.create(projectItem);
        closeToast(save);
        showSuccess("Project created");
        await this.loadProjects();
        return projectId;
      } catch (e: any) {
        showToastError("Failed to create project: " + parseError(e));
        console.error(e);
        return "";
      }
    },
    async updateProject(projectItem: ProjectItem): Promise<void> {
      try {
        const update = showLoading("Updating project");
        projectItem.service = null;
        await project.update(projectItem);
        closeToast(update);
        showSuccess("Project updated");
        await this.loadProjects();
      } catch (e: any) {
        showToastError("Failed to update project: " + parseError(e));
        console.error(e);
      }
    },
    async updateProjectUsers(id: string, users: UserProjectItem[]) {
      try {
        const update = showLoading("Updating project users");
        await project.updateUsers(id, users);
        closeToast(update);
        showSuccess("Project users updated");
        await this.loadProjects();
      } catch (e: any) {
        showToastError("Failed to update project users: " + parseError(e));
        console.error(e);
      }
    },
    async updateDefinitions(id: string, definitions: DefinitionItem[]) {
      try {
        const update = showLoading("Updating activity");
        await project.updateDefinitions(id, definitions);
        closeToast(update);
        showSuccess("Activity updated");
        await this.loadProjects();
      } catch (e: any) {
        showToastError("Failed to update activity: " + parseError(e));
        console.error(e);
      }
    },
    async updateDefinition(id: string, definition: DefinitionItem) {
      try {
        const update = showLoading("Updating activity");
        await project.updateDefinition(id, definition);
        closeToast(update);
        showSuccess("Activity updated");
        await this.loadProjects();
      } catch (e: any) {
        showToastError("Failed to update activity: " + parseError(e));
        console.error(e);
      }
    },
    async deleteDefinition(id: string, definitionId: string) {
      try {
        const deleteDef = showLoading("Deleting activity");
        await project.deleteDefinition(id, definitionId);
        closeToast(deleteDef);
        showSuccess("Activity deleted");
        await this.loadProjects();
      } catch (e: any) {
        showToastError("Failed to delete activity: " + parseError(e));
        console.error(e);
      }
    },
    async deleteProject(id: string) {
      try {
        await project.delete(id);
        await this.loadProjects();
      } catch (e: any) {
        showToastError("Failed to delete project: " + parseError(e));
        console.error(e);
      }
    },
  },
  getters: {
    getAll(): ProjectItem[] {
      return this.projects.data;
    },
    getAllLoaded(): boolean {
      return !this.projects.loader;
    },
  },
});
