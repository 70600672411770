<template>
  <div class="stripe-header">
    <div class="head">
      <img src="../assets/ABB-logo.svg" class="abb-img" />
      <img src="../assets/Divider.svg" class="divider-img" />
      <div class="product">
        <span>Monthly report</span>
        <span>Mexico</span>
      </div>
    </div>
    <nav class="nav">
      <router-link v-for="(item, index) in pages.find(a => a.role === userStore.getUserRole)?.pages"
        :key="getKey('nav', index)" :to="item.to" :class="{ active: item.isActive(router) }">{{ item.text
        }}</router-link>
    </nav>
    <div class="logged-user">
      <img src="../assets/Divider.svg" class="divider-img" />
      <div class="person">
        <img v-if="azureStore.getPhotoLoader" :src="createBlobUrl(azureStore.getPhoto)" alt="User Photo"
          class="person-photo person-photo--rounded" />
        <span>
          {{ Authorization.accountName }}</span>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Authorization } from "@/services/authenticate";
import { useAzureStore } from "@/stores/azure";
import { useUserStore } from "@/stores/user";
import { onBeforeMount } from "vue";
import { UserRole } from "@/services/entities/UserItem";
import { useRoute } from "vue-router";
import { getKey } from "@/common/util";
import { pages } from "@/common/pages";
import { isDevelopment } from "@/common/env";

const router = useRoute();
const azureStore = useAzureStore();
const userStore = useUserStore();

const createBlobUrl = (photo: any) => {
  if (photo && !isDevelopment) {
    const blob = azureStore.getPhoto;
    return URL.createObjectURL(blob);
  }
  return undefined;
};

onBeforeMount(async () => {
  await azureStore.loadMyPhoto();
});

// setTimeout(async () => {
//   await azureStore.loadPhoto();

// }, 2000);
</script>

<style scoped lang="scss">
.stripe-header {
  display: flex;
  height: 64px;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  flex-shrink: 0;
  width: 100%;
  /* elevation/3 */
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 0.5px 2px 0px rgba(0, 0, 0, 0.08),
    0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.logged-user {
  display: flex;
  align-items: center;

  .person {
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  a {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    text-decoration: none;
    max-width: 169px;
    padding: 0px 24px;
    flex-direction: column;
    align-items: center;
    color: var(--foreground-primary, #1f1f1f);
    /* body/large/weight-bold */
    font-family: ABBvoice;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    &.active {
      border-bottom: 4px solid var(--foreground-accent-primary, #000);
    }
  }
}

.head {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .abb-img {
    display: flex;
    padding-right: 20px;
    align-items: center;
    align-self: stretch;
  }

  .divider-img {
    width: 2px;
    align-self: stretch;
    fill: var(--border-bold, rgba(0, 0, 0, 0.24));
  }

  .product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 8px;

    span {
      font-weight: 300;

      &:first-child {
        font-weight: 500;
      }
    }
  }
}
</style>
