<template>
    <DxDataGrid :hover-state-enabled="true" :show-borders="false" :data-source="props.data" id="rateGridContainer"
        :selected-row-keys="selectedKeys" :auto-navigate-to-focused-row="true" key-expr="id">
        <DxFilterRow :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxStateStoring :enabled="true" type="localStorage" storage-key="rateGridContainer" />
        <DxColumn data-field="id" data-type="string" caption="ID" :visible="false" />
        <DxColumn data-field="name" data-type="string" caption="Name" />
        <DxColumn data-field="description" data-type="string" caption="Description" />
        <DxColumn data-field="value" data-type="number" caption="Value" />
        <DxColumn type="buttons" caption="Actions" :width="70">
            <DxButton hint="Edit rate" icon="edit" styling-mode="contained" type="normal" @click="edit" />
            <DxButton hint="Delete rate" icon="trash" styling-mode="contained" type="normal" @click="deleteRate" />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager :show-page-size-selector="true" :allowed-page-sizes="[5, 10, 20]" />
    </DxDataGrid>
</template>
  
<script setup lang="ts">
import { RateItem } from "@/services/entities/RateItem";
import DxDataGrid, {
    DxColumn,
    DxFilterRow,
    DxPaging, DxPager,
    DxStateStoring,
    DxHeaderFilter,
    DxButton
} from "devextreme-vue/data-grid";
import { ref } from "vue";

const emit = defineEmits(["edit", "delete"]);
const selectedKeys = ref([] as number[]);

const props = defineProps({
    data: { Type: [] as RateItem[], default: [] as RateItem[] },
});

function edit(item: any) {
    selectedKeys.value = [item.row.data.id];
    emit("edit", item.row.data.id);
}

function deleteRate(item: any) {
    emit("delete", item.row.data.id);
}
</script>
  
<style scoped lang="scss">
.buttons {
    margin-bottom: 10px;

    b {
        margin-right: 3px;
    }
}
</style>
  