<template>
  <TopBarVue v-if="route.name !== 'Login'"></TopBarVue>
  <router-view class="router-body" />
  <footer>
    © 2023 | ABB EUOPC
  </footer>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import TopBarVue from "./components/TopBar.vue";
const route = useRoute();

</script>

<style lang="scss">
@import "assets/scss/common.scss";
@import "vue-select/dist/vue-select.css";

.body {
  top: 100px;
  position: absolute;
  height: calc(100% - 100px);
  width: 100%;
  overflow: auto;
}

.router-body {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

</style>