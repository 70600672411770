import ApiConnector from "../apiConnector";
import { UserItem, UserRole } from "../entities/UserItem";
import { UserProjectItem } from "../entities/UserProjectItem";

class UserDataFetcher {
  private readonly apiConnector: ApiConnector;

  constructor(baseURL: string) {
    this.apiConnector = new ApiConnector(baseURL);
  }

  public async get(id: string) {
    return await this.apiConnector.get<UserItem>("user/" + id);
  }

  public async getProjectsForUser(id: string) {
    return await this.apiConnector.get<UserProjectItem[]>(`user/${id}/projects`);
  }

  public async getAll() {
    return await this.apiConnector.get<UserItem[]>("user/all");
  }

  public async getForTeamLeader(id: string) {
    return await this.apiConnector.get<UserItem[]>("user/teamleader/" + id);
  }

  public async updateData(id: string, user: UserItem) {
    return await this.apiConnector.put<string>("user/" + id, user);
  }

  public async create(user: UserItem) {
    return await this.apiConnector.post<void>("user", user);
  }

  public async update(user: UserItem) {
    return await this.apiConnector.put<void>("user", user);
  }

  public async deleteUserRate(id: string) {
    return await this.apiConnector.delete<void>("user/rate/" + id);
  }
  
  public async deleteFloatingDay(id: string) {
    return await this.apiConnector.delete<void>("user/floatingday/" + id);
  }

  public async deleteOtFond(id: string) {
    return await this.apiConnector.delete<void>("user/otcorrection/" + id);
  }

  public async deleteVacation(id: string) {
    return await this.apiConnector.delete<void>("user/vacation/" + id);
  }

  public async getLoggedUserInfo() {
    return await this.apiConnector.get<UserItem>("user");
  }

  public async getTeamLeaders() {
    return await this.apiConnector.get<UserItem[]>("user/teamleaders");
  }
}
export default UserDataFetcher;
