<template>
    <nav>
        <router-link :to="{ name: 'DailyReport' }" :class="{ active: router.name == 'DailyReport' }">Monthly</router-link>
        <router-link :to="{ name: 'YearlyReport' }" :class="{ active: router.name == 'YearlyReport' }">Yearly</router-link>
        <router-link :to="{ name: 'Invoicing' }" :class="{ active: router.name == 'Invoicing' }">Invoicing</router-link>
    </nav>
    <TileCard class="view-body">
        <template #body>
            <router-view></router-view>
        </template>
    </TileCard>
</template>

<script setup lang="ts">

import { useRoute } from "vue-router";
import TileCard from "@/components/util/TileCard.vue";
const router = useRoute();
</script>

<style scoped lang="scss">
.view-body {
    height: calc(100vh - 185px);
}

nav {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 0 0;
    margin-left: 24px;
    margin-bottom: -5px;
    margin-top: 15px;

    a {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
        max-width: 200px;
        align-self: stretch;
        text-decoration: none;
        flex-direction: column;
        align-items: center;
        color: var(--foreground-primary, #1f1f1f);
        /* body/large/weight-bold */
        font-family: ABBvoice;
        font-size: 16px;
        font-style: normal;
        line-height: 200%;
        padding: 0px 12px;
        border-radius: 8px 8px 0 0;

        &.active {
            font-weight: 700;
            background: var(--background-base, #fff);
        }
    }
}
</style>