<template>
    <div class="d-row project-body">
        <TileCard id="project-table" :loaded="settingsStore.getServicesLoaded">
            <template #body>
                <ServiceTable :data="settingsStore.getServices" @detail="loadInvoice">
                </ServiceTable>
            </template>
        </TileCard>
        <TileCard id="invoice" :loaded="reportStore.isInvoiceLoaded" v-if="state.showInvoice">
            <template #body>
                <TSInvoiceVue class="invoice-body" :data="reportStore.getInvoiceData" :year="state.invoice.year"
                    :service="settingsStore.getServices.find(a => a.id == state.invoiceId)"
                    :month="state.invoice.month + 1"></TSInvoiceVue>
            </template>
            <template #header>
                <div class="d-row">
                    <VueDatePicker v-model="state.invoice" month-picker :clearable="false" :teleport="true"
                        @update:model-value="loadInvoice(state.invoiceId)">
                    </VueDatePicker>
                    <div class="buttons"><button class="accent" @click="createPDF"
                            :disabled="state.pdfGenerating">Generate
                            PDF</button></div>
                </div>
            </template>
        </TileCard>
    </div>
</template>

<script setup lang="ts">
import TileCard from "@/components/util/TileCard.vue";
import ServiceTable from "@/components/tables/ServiceTable.vue";
import TSInvoiceVue from "@/components/report/TSInvoice.vue";
import { useReportStore } from "@/stores/report";
import { useSettingsStore } from "@/stores/setting";
import { closeToast, showLoading } from "@/toastification";
import jsPDF from "jspdf";
import { onMounted, reactive } from "vue";

const state = reactive({
    invoice: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() },
    showInvoice: false,
    invoiceId: 0,
    pdfGenerating: false,
});

const settingsStore = useSettingsStore();
const reportStore = useReportStore();

onMounted(async () => {
    await settingsStore.loadServices();
})

async function loadInvoice(serviceId: number) {
    state.showInvoice = true;
    state.invoiceId = serviceId;
    await reportStore.loadInvoiceData(serviceId, state.invoice.year, state.invoice.month + 1);
}


const createPDF = async () => {
    state.pdfGenerating = true;
    const loadToast = showLoading("Generating PDF", 0);
    const pdf = new jsPDF({
        unit: 'px',
        format: [680, 930]
    });

    try {
        let i = 0;
        while (true) {
            const page = document.getElementById(`page_${i}`);
            if (!page) {
                break;
            }
            if (i > 1) {
                pdf.addPage();
            }
            await pdf.html(page, { y: 930 * i });
            i++;
        }
        pdf.save(`${reportStore.getInvoiceData.service.blpgCode}_${state.invoice.year}_${state.invoice.month + 1}.pdf`);
    } catch (error) {
        console.error(error);
    } finally {
        state.pdfGenerating = false;
        closeToast(loadToast);
    }
}

</script>

<style scoped lang="scss">
#invoice, #project-table {
    overflow: auto;
    height: calc(100vh - 240px);
}

.project-body {
    align-content: start;
    margin-top: 10px;
}
</style>